/**************************************************
 * Nombre:       IngresoPuja
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, IconButton, TextField, Typography} from "@mui/material";
import {Add, Remove} from "@mui/icons-material";
import {useState} from "react";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {theme} from "../../../Tema";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {GRADIENTE} from "../../../Constantes";


const IngresoPuja = ({valoresDisponibles, ingresarPuja}) => {
    const {sCell} = useResponsive()
    const [index, setIndex] = useState(0)

    const aumentar = () => {

        if (index < valoresDisponibles.length - 1) {
            setIndex((prev) => prev + 1)
        } else {
            setIndex(0)
        }
    }

    const disminuir = () => {
        if (index > 0) {
            setIndex((prev) => prev - 1)
        } else {
            setIndex(valoresDisponibles.length - 1)
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginBottom: -2}}>
                <Typography sx={{color: theme.palette.secondary.main, fontWeight: 600, fontSize: 18}}>Puja con un
                    valor</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{background: GRADIENTE, px: 4, py: 3, borderRadius: 20}}
                >

                    <Grid item container lg={5} sm={12} xs={5} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{
                            fontSize: sCell ? 24 : 32,
                            fontWeight: 600,
                            color: '#fff'
                        }}>{formatoMoneda(valoresDisponibles[index])}</Typography>
                    </Grid>

                    <Grid item container lg={2} sm={12} xs={3} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={6} sm={12} xs={6} sx={{justifyContent: 'center'}}>
                                <IconButton
                                    onClick={() => disminuir()}
                                >
                                    <Remove sx={{fill: '#fff'}}/>
                                </IconButton>
                            </Grid>

                            <Grid item container lg={6} sm={12} xs={6} sx={{justifyContent: 'center'}}>
                                <IconButton
                                    onClick={() => aumentar()}
                                >
                                    <Add sx={{fill: '#fff'}}/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item container lg={5} sm={12} xs={4} sx={{justifyContent: 'flex-end'}}>
                        <Button
                            color={'action'}
                            onClick={() => {
                                aumentar()
                                ingresarPuja(valoresDisponibles[index])
                            }
                            }
                            sx={{px: 6}}
                        >HIT</Button>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )

}
export default IngresoPuja    