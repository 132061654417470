/**************************************************
 * Nombre:       NosotrosApartado
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import fondo from '../../Recursos/fondo_nosotros.png'
import logo_jamssa from '../../Recursos/logo_jamssa.png'
import logo_color from '../../Recursos/logo_color.svg'
import {theme} from "../../Tema";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useNavigate} from "react-router-dom";

const NosotrosApartado = () => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        onClick={() => navigate('/Nosotros')}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            border: 20,
                            borderColor: '#fff',
                            boxShadow: '5.36px 5.36px 21.45px 5.36px #00000010',
                            borderRadius: 20,
                            background: `url(${fondo})`,
                            p: 6,
                            backgroundSize: 'cover',
                            backgroundPosition: 'rigth',
                            cursor: 'pointer'
                        }}
                    >

                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{color: theme.palette.primary.main, fontSize: 40, fontWeight: 800}}>
                                        Nosotros
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                    <Typography
                                        sx={{
                                            color: theme.palette.secondary.main, fontSize: 16, fontWeight: 300,
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 5,
                                            textOverflow: "ellipsis",
                                        }}>
                                        En Dream Free, transformamos la manera de adquirir tecnología de alta gama.
                                        Nacemos con el propósito de hacer accesibles los dispositivos más innovadores,
                                        utilizando una plataforma web donde puedes participar en subastas interactivas
                                        llenas de emoción, estrategia y precios inigualables. Nuestro modelo, respaldado
                                        por la tecnología Blockchain y basado en el apalancamiento, garantiza procesos
                                        seguros y transparentes para todos nuestros usuarios.
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <img src={logo_jamssa} width={150} height={'auto'}/>
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 3 : 3}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontSize: 40,
                                        fontWeight: 800,
                                        lineHeight: 1.1
                                    }}>
                                        Nuestros Sueños
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                    <Typography
                                        sx={{
                                            color: theme.palette.secondary.main, fontSize: 16, fontWeight: 300,
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 5,
                                            textOverflow: "ellipsis",
                                        }}>
                                        Imaginamos un futuro en el que cada persona, sin importar su situación
                                        económica, pueda disfrutar de los mejores equipos tecnológicos a precios
                                        accesibles, gracias a nuestro innovador modelo de subastas. Adicionalmente,
                                        creemos en la construcción de una comunidad y un ecosistema donde la calidad, la
                                        innovación y la accesibilidad se encuentren en perfecta armonía, y donde cada
                                        transacción no solo sea una compra, sino una experiencia memorable, asegurando
                                        un futuro donde la tecnología avanzada sea un bien común, no un lujo. Creemos en
                                        el poder del apalancamiento para transformar vidas, empoderar a nuestros
                                        participantes y construir una comunidad dinámica y conectada.
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <img src={logo_color} width={200} height={'auto'}/>
                                </Grid>


                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default NosotrosApartado