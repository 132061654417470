/**************************************************
 * Nombre:       CabeceraLista
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {React, useEffect, useState} from "react";
import {obtenerDoc} from "../../../Servicios/BD/obtenerDoc";
import {obtenerPosibilidades} from "../../../Utilidades/obtenerPosibilidades";
import {useNavigate} from "react-router-dom";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Pdf_Certificado from "../../PDfsV2/Pdf_Certificado";
import CuadroAvatarParticipacion from "./CuadroAvatarParticipacion";
import QRCode from 'qrcode'

const LineaListaParticipaciones = ({item}) => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()
    const [subasta, setSubasta] = useState({})
    const [imagen, setImagen] = useState('')
    const [tickets, setTickets] = useState([])
    const [qrHashSubasta, setQrHashSubasta] = useState('')
    const [qrContratoString, setQrContratoString] = useState('')


    const crearQrs = () => {


        QRCode.toDataURL(item.hashTicket)
            .then(url => {

                setImagen(url)
            })
            .catch(err => {

            })

        if (item.ticketsParticipando) {

            setTickets([])
            for (let i = 0; i < item.ticketsParticipando.length; i++) {
                let ticket = item.ticketsParticipando[i]
                let obj = {...ticket}
                QRCode.toDataURL(ticket.hash)
                    .then(url => {
                        obj.img = url
                    })
                    .catch(err => {

                    })

                setTickets((arr) => arr.concat(obj))

            }
        }


        QRCode.toDataURL(item.hash)
            .then(url => {

                setQrHashSubasta(url)
            })
            .catch(err => {

            })

        QRCode.toDataURL(item.contratoString)
            .then(url => {

                setQrContratoString(url)
            })
            .catch(err => {

            })

    }

    useEffect(() => {

        obtenerDoc('subastas', item.idSubastaId).then((dox) => {
            if (dox.res && dox.data) {
                setSubasta(dox.data)
            }
        })

    }, [item]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                px: sCell ? 0 : 6,
                py: 2,
                borderBottom: 1,
                borderColor: theme.palette.secondary.main
            }}
        >

            <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'center'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{cursor: 'pointer'}}
                    onClick={() => navigate('/Detalle/' + item.idSubastaId)}
                >

                    <Grid item container lg={3} sm={3} xs={3}
                          sx={{justifyContent: 'flex-start', marginBottom: sCell ? 2 : 0}}>
                        <CuadroAvatarParticipacion item={item}/>
                    </Grid>

                    <Grid item container lg={9} sm={9} xs={9} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{
                                    color: theme.palette.secondary.main,
                                    fontWeight: 800,
                                    fontSize: 18,
                                    lineHeight: 1.1
                                }}>{item.producto}</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                <Typography sx={{
                                    color: theme.palette.secondary.main,
                                    fontWeight: 400,
                                    fontSize: 14
                                }}>{item.idSubasta}</Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item container lg={2} sm={2} xs={4} sx={{justifyContent: 'center'}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {`${subasta.participaciones && subasta.participaciones.length} de ${subasta.numTickets}`}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={2} xs={4} sx={{justifyContent: 'center'}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {subasta.estado}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={2} xs={4} sx={{justifyContent: 'center'}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {`${obtenerPosibilidades({
                        numTickets: subasta.numTickets,
                        numPujas: item.ticketsParticipando.length
                    })}%`}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'center', marginTop: sCell ? 2 : 0}}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        {item && item.ticketsParticipando.length === tickets.length && qrHashSubasta ?
                            <PDFDownloadLink
                                document={<Pdf_Certificado participacion={item} tikets={tickets} imagen={imagen}
                                                           qrHashSubasta={qrHashSubasta}
                                                           qrContratoString={qrContratoString}
                                />}
                                fileName={"Certificado de participacion"}
                                style={{width: '100%'}}>
                                <Button
                                    fullWidth sx={{
                                    backgroundColor: '#2626ED10',
                                    color: theme.palette.secondary.main,
                                    px: 3,
                                    boxShadow: 0,
                                    pt: 0.5,
                                    pb: 0.7
                                }}>
                                    Descargar
                                </Button>
                            </PDFDownloadLink>

                            :

                            <Button
                                onClick={() => crearQrs()}
                                fullWidth sx={{
                                backgroundColor: '#2626ED10',
                                color: theme.palette.secondary.main,
                                px: 3,
                                boxShadow: 0,
                                pt: 0.5,
                                pb: 0.7
                            }}>
                                Certificado
                            </Button>
                        }

                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Button
                            onClick={() => navigate('/Detalle/' + subasta.id)}
                            fullWidth
                            sx={{
                                backgroundColor: '#2626ED10',
                                color: theme.palette.secondary.main,
                                px: 3,
                                boxShadow: 0,
                                pt: 0.5,
                                pb: 0.7
                            }}>
                            Ver Subasta
                        </Button>
                    </Grid>

                </Grid>

            </Grid>


        </Grid>
    )

}
export default LineaListaParticipaciones