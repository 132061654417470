/**************************************************
 * Nombre:       EconomiaColaborativa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import logo from '../../Recursos/logo_jamssa.png'
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";


const Nosotros_Jamssa = () => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row-reverse"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-end'}}>
                            <img src={logo} width={'80%'} height={'auto'}/>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={8} sm={12} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 700,
                                        fontSize: 40,
                                        lineHeight: 1
                                    }}>
                                        Nosotros
                                    </Typography>
                                </Grid>

                                <Grid item container lg={10} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <Typography sx={{
                                        color: theme.palette.secondary.main,
                                        fontWeight: 300,
                                        fontSize: 16,
                                        lineHeight: 1.4,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        En Dream Free, transformamos la manera de adquirir tecnología de alta gama.
                                        Nacemos con el propósito de hacer accesibles los dispositivos más innovadores,
                                        utilizando una plataforma web donde puedes participar en subastas interactivas
                                        llenas de emoción, estrategia y precios inigualables. Nuestro modelo, respaldado
                                        por la tecnología Blockchain y basado en el apalancamiento, garantiza procesos
                                        seguros y transparentes para todos nuestros usuarios.
                                        <br/>
                                        <br/>
                                        Cada subasta es una puerta abierta a la modernidad, donde la calidad premium y
                                        el valor excepcional se encuentran al alcance de todos. Creemos en un mundo
                                        donde la tecnología de vanguardia no es un lujo, sino una oportunidad para
                                        mejorar tu vida.


                                    </Typography>
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Nosotros_Jamssa