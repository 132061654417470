/**************************************************
 * Nombre:       Seccion_Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {usuarioContext} from "../../App";
import PortadaPerfil from "../Componentes/PortadaPerfil";
import Indicadores_Perfil from "../Componentes/SubComponetes/Indicadores_Perfil";
import Participaciones_Beneficiadas_Perfil from "../Componentes/Participaciones_Beneficiadas_Perfil";
import Participaciones_Usuario from "../Componentes/Participaciones_Usuario";
import {useNavigate, useParams} from "react-router-dom";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {contarColeccionWheres} from "../../Servicios/BD/Contadores/contarColeccionWheres";
import {where} from "firebase/firestore";
import {Helmet} from "react-helmet";
import {irArriba} from "../../Utilidades/irArriba";
import {guardarStringLocal} from "../../Servicios/BDLocal/guardarStringLocal";

const Seccion_Perfil = () => {
    const {sCell, masSM} = useResponsive()
    const parms = useParams()
    const [perfil, setPerfil] = useState({})
    const cData = useContext(usuarioContext)
    const [indicadores, setIndicadores] = useState({})
    const [hayBeneficiadas, setHayBeneficiadas] = useState(false)
    const navigate = useNavigate()


    const obetenerIndicadores = (usuario) => {

        let ind = {}

        ind.numMedallas = usuario.numMedallas
        if (usuario.id === cData.usuario.id) {
            ind.numTickets = cData.numTickets

            let wp = [where('idUsuario', '==', usuario.id)]
            contarColeccionWheres({coleccion: 'participaciones', wheres: wp}).then((des) => {
                if (des.res) {
                    ind.numParticipaciones = des.data
                } else {
                    ind.numParticipaciones = 0
                }

                setIndicadores(ind)
            })

        } else {
            let w = [where('idUsuario', '==', usuario.id), where('idSubasta', '==', 'none')]
            contarColeccionWheres({coleccion: 'tickets', wheres: w}).then((dox) => {
                if (dox.res) {
                    ind.numTickets = dox.data
                } else {
                    ind.numTickets = 0
                }

                let wp = [where('idUsuario', '==', usuario.id)]
                contarColeccionWheres({coleccion: 'participaciones', wheres: wp}).then((des) => {
                    if (des.res) {
                        ind.numParticipaciones = des.data
                    } else {
                        ind.numParticipaciones = 0
                    }

                    setIndicadores(ind)
                })

            })
        }


    }


    useEffect(() => {
        irArriba()

        if (parms && parms.id && parms.id !== 'undefined') {
            obtenerDoc('usuarios', parms.id).then((dox) => {
                console.log(dox)
                if (dox.res) {
                    setPerfil(dox.data)
                    obetenerIndicadores(dox.data)
                }
            })
        } else if (cData.usuario.id) {
            setPerfil(cData.usuario)
            obetenerIndicadores(cData.usuario)
        } else {
            navigate('/Home')
        }


        if (parms && parms.ref) {
            console.log('ref',parms.ref)
            guardarStringLocal({clave: 'referenciado', string: parms.ref})
        }


    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Helmet>
                <meta charSet="utf-8"/>

                <meta property="og:title" content={`Dream Free | ${perfil.nick}`}/>
                <meta property="og:image" content={perfil.imgCuadrado}/>
                <meta property="og:description"
                      content="Compras interactivas, Consigue el 86% de descuento participando en nuestras subastas transparentes y respaldadas por la tecnologia BlockChain"/>


                <title>Dream Free | Perfil</title>
            </Helmet>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <PortadaPerfil perfil={perfil}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Indicadores_Perfil indicadores={indicadores} perfil={perfil}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{
                      justifyContent: 'flex-start',
                      marginTop: hayBeneficiadas && sCell ? 10 : hayBeneficiadas ? 12 : -50,
                      opacity: hayBeneficiadas ? 1 : 0
                  }}>

                <Participaciones_Beneficiadas_Perfil perfil={perfil}/>


            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 8 : 12, marginBottom: 6}}>
                <Participaciones_Usuario titulo={'Lista de participaciones'} perfil={perfil}/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Perfil