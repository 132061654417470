/**************************************************
 * Nombre:       Dialogo_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logo_color.svg'
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Login from "../../PaginaConstruccion/Formularios/Formulario_Login";
import {funIngresarCorreoPass} from "../../Servicios/Auth/funIngresarCorreoPass";
import {funRecuperarContrasena} from "../../Servicios/Auth/funRecuperarContrasena";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import {theme} from "../../Tema";
import {useState} from "react";
import Formulario_Registro from "../../PaginaConstruccion/Formularios/Formulario_Registro";
import {funCrearUsuario} from "../../Servicios/Auth/funCrearUsuario";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {getID} from "../../Servicios/BD/useEscucharUsuarioToCorreo";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {obtenerColleccionWhere} from "../../Servicios/BD/obtenerColleccionWhere";
import {where} from "firebase/firestore";
import {obtenerStringLocal} from "../../Servicios/BDLocal/obtenerStringLocal";

const Dialogo_Login = ({cerrar, realizado}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [ingresando, setIngresando] = useState(true)
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const {abrirCargador, Cargador, cerrarCargador} = useLoaders({logo: logo})

    const ingresar = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Ingresando...')
            funIngresarCorreoPass(entidad.correo, entidad.pass).then((dox) => {
                if (dox.res) {
                    cerrarCargador()
                    cerrar()
                    realizado()
                } else {
                    cerrarCargador()
                }
            })
        })

    }

    const registrar = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Registrando usuario nuevo')
            funCrearUsuario(entidad.correo, entidad.pass).then((dox) => {
                if (dox.res) {
                    let obj = entidad
                    obj.id = getID(entidad.correo)
                    obj.tipo = 'Crack'
                    obj.numMedallas = 0

                    obtenerAvatares(obj)

                } else {
                    cerrarCargador()
                }
            })
        })
    }

    const obtenerAvatares = (obj) => {
        let wheres = [where('tipo', '==', 'Por Defecto')]
        obtenerColleccionWhere({col: 'avatares', wheres: wheres}).then((dox) => {
            if (dox.res) {
                let avatares = dox.data
                obj.avatares = avatares
                obj.imgCuadrado = avatares[0].imgCuadrado
                obj.imgRectangular = avatares[0].imgRectangular

                guardarDoc('usuarios', obj).then((des) => {
                    if (des.res) {
                        let ref = obtenerStringLocal({clave: 'referenciado'})
                        console.log('REFERENCIA', ref)
                        if (ref) {
                            guardarReferencia(obj, ref)
                        }else{
                            cerrar()
                            cerrarCargador()
                            alert('Registro relizado con exito\nSeras redirigido al tu perfil')
                        }
                    }
                })


            }
        })
    }

    const guardarReferencia = (ent, ref) => {
        let obj = {
            idUsuarioCreado: ent.id,
            usuarioCreado: ent.nick,
            correoUsuario: ent.correo,
            fecha: new Date().getTime(),
            referenciado: ref
        }
        guardarDoc('referencias', obj).then((dox) => {
            cerrar()
            cerrarCargador()
            alert('Registro relizado con exito\nSeras redirigido al tu perfil')
        })
    }

    const recuperar = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Verificando correo de usuario')
            funRecuperarContrasena(entidad.correo).then((dox) => {
                if (dox.res) {
                    cerrarCargador()
                    alert('Las instrucciones han sido enviadas al correo ' + entidad.correo)
                } else {
                    cerrarCargador()
                    alert(dox.message)
                }
            })
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: sCell ? 2 : 4}}
        >

            <Cargador/>


            {ingresando ?

                <>
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -2}}>
                        <Formulario_Login props={props}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                        <ButtonBase
                            onClick={() => recuperar()}
                        >
                            <Typography sx={{fontWeight: 300, fontSize: 14}}>Olvide mi contraseña</Typography>
                        </ButtonBase>
                    </Grid>
                </>

                :

                <>
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -2}}>
                        <Formulario_Registro props={props}/>
                    </Grid>
                </>

            }


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Typography>{ingresando ? 'No tienes una cuenta?' : 'Ya tienes una cuenta?'} <span>

                    {ingresando ?
                        <ButtonBase
                            onClick={() => setIngresando(false)}
                            sx={{py: 0, marginTop: -0.51}}>
                            <Typography
                                sx={{color: theme.palette.primary.main, fontWeight: 600}}>Registrate </Typography>
                        </ButtonBase>
                        :

                        <ButtonBase
                            onClick={() => setIngresando(true)}
                            sx={{py: 0, marginTop: -0.51}}>
                            <Typography
                                sx={{color: theme.palette.primary.main, fontWeight: 600}}> Ingresar </Typography>
                        </ButtonBase>
                    }

                    </span> </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>

                {ingresando ?
                    <Button
                        onClick={() => ingresar()}
                        sx={{px: 6, py: 1}}>
                        Ingresar
                    </Button>
                    :
                    <Button
                        onClick={() => registrar()}
                        sx={{px: 6, py: 1}}>
                        Registrarse
                    </Button>
                }

            </Grid>


        </Grid>
    )

}
export default Dialogo_Login